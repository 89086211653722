<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-modal
    id="modal-reparto-event-handler"
    ref="modal-reparto-event-handler"
    v-model="show"
    ok-only
    ok-title="Crea"
    centered
    no-close-on-backdrop
    size="md"
    :title="titleEventHandler"
    @ok.prevent="handleOk"
  >
    <!-- {{ reparto }} -->
    <validation-observer ref="validation-observer-reparto-modal-event-handler">
      <b-row no-gutters>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="nome reparto"
            rules="required"
          >
            <b-form-group
              label="Nome Reparto"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="reparto.nome"
                  placeholder="nome"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="codice"
            rules="required"
          >
            <b-form-group
              label="Codice Reparto"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="reparto.codice"
                  placeholder="codice"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group
            label="Reparto Padre"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="rep. padre"
              rules="required"
            >
              <v-select
                v-model="reparto.idRepartoPadre"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                filterable
                placeholder="reparto padre"
                :reduce="valore => valore.id"
                :options="repartiPadreOption"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  Nessuna Opzione disponibile.
                </template>
              </v-select>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="'Punti Vendita Associati'"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="Prod. Associato"
              rules="required"
            >
              <v-select
                v-model="reparto.puntiVendita"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                value="id"
                multiple
                placeholder="punti vendita associati"
                :reduce="(val) => ({ id: val.id })"
                filterable
                :options="puntiVenditaOption"
                @input="limiterSelection"
                @open="getPuntiVenditaOption"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Ricerca Punti Vendita...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #loading="{ search, searching, loading }">
                  <div>Caricamento Punti Vendita...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #searching="{ search, searching, loading }">
                  <div>Sto cercando...</div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="descrizione"
            rules="required"
          >
            <b-form-group
              label="Descrizione Reparto"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="reparto.descrizione"
                  placeholder="descrizione"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
      />
    </validation-observer>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import vSelect from 'vue-select'
import { getPuntiVendita } from '@/@core/api-service/logistic/puntiVendita'
// import { getCombo } from '@/@core/api-service/shared/combo'
import axios from 'axios'

Vue.use(ToastPlugin)

export default {
  components: {
    BRow, BCol, BFormGroup, BInputGroup, BFormInput, ValidationProvider, ValidationObserver, vSelect,
  },
  data() {
    return {
      show: false,
      titleEventHandler: '',
      reparto: {
        id: 0,
        nome: '',
        codice: '',
        descrizione: '',
        idRepartoPadre: '',
        ordinamento: 0,
        puntiVendita: [],
        escludiPuntiVendita: 'N',
      },
      required,
      locale: 'it',
      repartiOption: [],
      puntiVenditaOption: [],
    }
  },
  mounted() {
    localize(this.locale)
  },
  computed: {
    repartiPadreOption() {
      // this.$store.getters['logistic/getRecapiti']
      const options =  this.$store.getters['logistic/getReparti']
      options.unshift({ id: 0, nome: 'NESSUNO'})
      return options
      // return this.repartiOption
    },
  },
  methods: {
    getPuntiVenditaOption() {
      getPuntiVendita().then(res => {
        if(res.data.esito === 'OK') {
          this.puntiVenditaOption = [...res.data.puntivendita]
        }
      })
    },
    handleOk() {
      this.setReparti()
    },
    setReparto(reparto) {
      this.reparto = { ...reparto }
    },
    openModal() {
      this.show = true
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    async setReparti() {
      console.log(this.reparto)
      if (!Object.prototype.hasOwnProperty.call(this.reparto, 'puntiVendita') ) {
        this.reparto.puntiVendita = []
      }
      this.reparto.escludiPuntiVendita = 'N'
      await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([{ ...this.reparto }]),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Log-Reparto',
            metodo: 'setReparto',
            ACL: '',
          },
        })
        .then(res => {
        console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok')
          const reparti = [...res.data.reparti.map(el => ({
            id: el.id,
            nome: el.nome,
            descrizione: el.descrizione,
            codice: el.codice,
            idRepartoPadre: el.id_reparto_padre,
            ordinamento: el.ordinamento,
            puntiVendita: [...el.puntiVendita]
          }))]
          // console.log('reparti', reparti)
          this.$emit('update-reparti', reparti)
          this.resetReparto()
          this.$nextTick(() => {
            this.$refs['modal-reparto-event-handler'].toggle('#toggle-btn')
          })
        } else if (res.data.esito === 'KO') {
          this.$refs.toastRef.show({
            title: 'Errore Inserimento', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
          })
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    limiterSelection(e) {
      if(e.length > 1) {
        e.pop()
      }
    },
    resetReparto() {
      this.reparto = {
        id: 0,
        nome: '',
        codice: '',
        descrizione: '',
        idRepartoPadre: '',
        ordinamento: 0,
        puntiVendita: [],
        escludiPuntiVendita: 'N',
      }
    },
  },
}
</script>

<style>

</style>
