var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-reparto-event-handler",attrs:{"id":"modal-reparto-event-handler","ok-only":"","ok-title":"Crea","centered":"","no-close-on-backdrop":"","size":"md","title":_vm.titleEventHandler},on:{"ok":function($event){$event.preventDefault();return _vm.handleOk($event)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"validation-observer-reparto-modal-event-handler"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"nome reparto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome Reparto","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"nome"},model:{value:(_vm.reparto.nome),callback:function ($$v) {_vm.$set(_vm.reparto, "nome", $$v)},expression:"reparto.nome"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"codice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codice Reparto","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"codice"},model:{value:(_vm.reparto.codice),callback:function ($$v) {_vm.$set(_vm.reparto, "codice", $$v)},expression:"reparto.codice"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Reparto Padre","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"rep. padre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"filterable":"","placeholder":"reparto padre","reduce":function (valore) { return valore.id; },"options":_vm.repartiPadreOption},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Nessuna Opzione disponibile. ")]}}],null,true),model:{value:(_vm.reparto.idRepartoPadre),callback:function ($$v) {_vm.$set(_vm.reparto, "idRepartoPadre", $$v)},expression:"reparto.idRepartoPadre"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Punti Vendita Associati',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Prod. Associato","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"value":"id","multiple":"","placeholder":"punti vendita associati","reduce":function (val) { return ({ id: val.id }); },"filterable":"","options":_vm.puntiVenditaOption},on:{"input":_vm.limiterSelection,"open":_vm.getPuntiVenditaOption},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca Punti Vendita...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento Punti Vendita...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}}],null,true),model:{value:(_vm.reparto.puntiVendita),callback:function ($$v) {_vm.$set(_vm.reparto, "puntiVendita", $$v)},expression:"reparto.puntiVendita"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrizione Reparto","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"descrizione"},model:{value:(_vm.reparto.descrizione),callback:function ($$v) {_vm.$set(_vm.reparto, "descrizione", $$v)},expression:"reparto.descrizione"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('ejs-toast',{ref:"toastRef",attrs:{"id":"toast_type","position":{ X: 'Right' }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }